import clsx from 'clsx';
import React from 'react';

import Button from '@/components/global/Button';

import { IS_PAUSED, IS_PLAYING, PAUSE_ICON, PLAY_ICON } from '../constants';
import { DefaultControlsProps } from '../types';
import styles from '../VideoHero.module.scss';
import { useVideoHero } from '../VideoHeroProvider';

const getButtonStyle = ({ color, activeColor }) => {
  const style = {};
  if (color) style['--dark-grey'] = color;
  if (activeColor) style['--white'] = activeColor;
  return style;
};

export const DefaultControls: React.FC<DefaultControlsProps> = ({ color, activeColor }) => {
  const { play, pause, videoStatus } = useVideoHero();

  return (
    <div style={getButtonStyle({ color, activeColor })} className={styles.DefaultControls}>
      <Button
        className={clsx(styles.DefaultControls__button, {
          [styles['DefaultControls__button--active']]: videoStatus === IS_PLAYING
        })}
        title="Pause"
        callBack={() => play()}
        variant="ghost"
        size="none"
      >
        {PLAY_ICON}
      </Button>
      &nbsp;
      <Button
        className={clsx(styles.DefaultControls__button, {
          [styles['DefaultControls__button--active']]: videoStatus === IS_PAUSED
        })}
        title="Pause"
        callBack={() => pause()}
        variant="ghost"
        size="none"
      >
        {PAUSE_ICON}
      </Button>
    </div>
  );
};

export default DefaultControls;
