import { useEffect, useRef, useState } from 'react';

import createSafeContext from '@/lib/useSafeContext';

import { IS_PAUSED, IS_PLAYING } from './constants';
import type { VideoHeroContextProps, VideoHeroProviderProps } from './types';

export const [useVideoHero, Provider] = createSafeContext<VideoHeroContextProps>();

export const VideoHeroProvider: React.FC<VideoHeroProviderProps> = ({
  children,
  video,
  autoPlay,
  loop,
  poster,
  videoUrl
}) => {
  const videoRef = useRef(null);
  const [muted, setMuted] = useState(false);
  const [videoStatus, setVideoStatus] = useState('');

  useEffect(() => {
    if (autoPlay) {
      play();
      mute(autoPlay);
    }
  }, [autoPlay]);

  const play = () => {
    videoRef.current?.play()?.then(() => setVideoStatus(IS_PLAYING));
  };

  const pause = () => {
    videoRef.current?.pause();
    setVideoStatus(IS_PAUSED);
  };

  const mute = (value) => {
    setMuted(value);
  };

  return (
    <Provider
      value={{
        play,
        pause,
        mute,
        autoPlay,
        muted,
        loop,
        video,
        videoRef,
        videoStatus,
        poster,
        videoUrl
      }}
    >
      {children}
    </Provider>
  );
};
