import { useSbImage } from '@/lib/hooks';

import type { VideoHeroProps } from '../types';
import styles from '../VideoHero.module.scss';
import { useVideoHero } from '../VideoHeroProvider';

export const VideoPlayer: React.FC<VideoHeroProps> = () => {
  const { autoPlay, videoRef, play, pause, muted, video, loop, poster, videoUrl } = useVideoHero();
  const { getSbImage } = useSbImage();

  const getPreloadState = () => {
    if (!autoPlay) return 'none';
    return 'metadata';
  };

  return (
    <>
      <video
        ref={videoRef}
        // id={`${file.id}`}
        autoPlay={autoPlay}
        poster={getSbImage(poster?.filename, 1500)}
        muted={autoPlay ? autoPlay : muted}
        preload={getPreloadState()}
        loop={loop}
        playsInline
        className={styles.video}
        {...{
          onTimeUpdate: () => {
            if (videoRef.current.ended) {
              if (loop) {
                play();
              } else {
                pause();
              }
            }
          }
        }}
      >
        <source src={video.filename || videoUrl} type="video/mp4" />
      </video>
    </>
  );
};

export default VideoPlayer;
